<template>
    <form @submit.prevent="submitForm">
        <div v-if="showError" class="error-msg">
            <p>{{ errorMsg }}</p>
        </div>
        <div v-if="showSucces" class="succes-msg">
            <p>{{ succesMsg }}</p>
        </div>

        <div class="form-row">
            <span class="field-wrapper email">
                <label class="top-level" for="user-email">E-mail</label>
                <div class="input-wrapper">
                    <input type="email" id="user-email" name="username" placeholder="E-mail" @keyup="changeInput()"
                        v-model="username" />
                </div>
            </span>
        </div>

        <div class="form-row">
            <span class="field-wrapper password">
                <label class="top-level" for="user-new-password">Password</label>
                <div class="input-wrapper">
                    <input id="user-new-password" name="user-new-password" type="password" placeholder="Password"
                        v-model="newPassword" @keyup="changeInput()" :class="{ error: error }" />
                </div>
            </span>
        </div>

        <div class="form-row">
            <span class="field-wrapper password">
                <label class="top-level" for="user-new-password-confirm">Confirm password</label>
                <div class="input-wrapper">
                    <input id="user-new-password-confirm" name="user-new-password-confirm" type="password"
                        placeholder="Password" v-model="newPasswordAgain" @keyup="changeInput()"
                        :class="{ error: error }" />
                </div>
            </span>
        </div>

        <div class="form-row">
            <span class="field-wrapper checkbox">
                <div class="input-wrapper">
                    <input type="checkbox" id="agree-to-privacy-policy" name="agree-to-privacy-policy"
                        v-model="checkedPrivacy" :class="{ error: error }" />
                    <label for="agree-to-privacy-policy">I agree with the
                        <router-link target="_blank" to="/privacy-voorwaarden">
                            privacy policy
                        </router-link>
                    </label>
                </div>
            </span>
        </div>

        <button :disabled="showSucces" data-txt="Activate" class="button submit">
            Activate
        </button>
    </form>
</template>

<script>
// @import constants
import {
    activateAccount,
    // getTempToken,
    // getUserToken,
} from "@streampac.io/chef_sp_1";

export default {
    inject: ["isAuthenticated"],
    data() {
        return {
            newPassword: "",
            newPasswordAgain: "",
            savedToken: "",
            showError: false,
            errorMsg: "",
            showSucces: false,
            succesMsg: "",
            username: "",
            checkedPrivacy: false,
            activateAccountId: this.$route.params.activateAccountId,
        };
    },
    created() {
        // if (this.isAuthenticated) {
        //     getUserToken((token) => {
        //         this.savedToken = token;
        //     });
        // } else {
        //     getTempToken((token) => {
        //         this.savedToken = token;
        //     });
        // }
    },
    methods: {
        submitForm() {
            if (
                this.password == this.newPasswordAgain ||
                this.password == "" ||
                this.newPasswordAgain == ""
            ) {
                this.showError = true;
                this.errorMsg = "Your passwords do not match.";
            } else if (
                this.username != "" &&
                this.password != "" &&
                this.newPasswordAgain != "" &&
                this.checkedPrivacy === true
            ) {
                const postObj =
                    "?id=" +
                    this.activateAccountId +
                    "&username=" +
                    encodeURIComponent(this.username) +
                    "&password=" +
                    encodeURIComponent(this.newPassword);

                activateAccount(this.isAuthenticated, postObj).then((response) => {
                    if (response.succes) {
                        if (response.resultCode === 1000) {
                            this.succesMsg = "Your account is activated!";
                            this.showSucces = true;

                            setTimeout(() => {
                                this.$router.replace({ name: "Home" });
                            }, 2000);
                        } else {
                            this.showError = true;
                            this.errorMsg = "Something went wrong..";
                        }
                    } else {
                        this.showError = true;
                        this.errorMsg = "Something went wrong..";
                    }
                });
            } else {
                this.showError = true;
                this.errorMsg =
                    "Please make sure all fields are filled in correctly";
            }
        },
        changeInput() {
            this.showError = false;
            this.errorMsg = "";
            this.showSucces = false;
            this.succesMsg = "";
        },
    },
};
</script>
