<template>
    <HeroPage v-if="pageData" :data="pageData" heroType="center" />

    <section class="sign-up-section" v-if="pageData">
        <div class="inner-wrapper">
            <div class="container">
                <div class="column-wrapper fullwidth">
                    <div class="column form">
                        <div class="title-wrapper">
                            <h2 class="column-title">Activate your account</h2>
                            <p class="column-subtitle">
                                You're almost there
                            </p>
                        </div>
                        <ActivateAccountForm />
                        <router-link class="close-btn" to="/"></router-link>
                    </div>
                    <div class="account-login-mini-bar">
                        Already have an account?
                        <button type="button" class="text-btn" @click="showLoginPopup()">
                            Login
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    fetchPageBySlug,
    clearData
} from "@streampac.io/chef_sp_1";

//@import component
import HeroPage from "@/components/HeroPage";
import ActivateAccountForm from "@/components/Forms/ActivateAccountForm";

export default {
    data() {
        return {
            pageData: null,
        };
    },
    inject: ["isAuthenticated", "setAuthenticated"],
    components: {
        HeroPage,
        ActivateAccountForm,
    },
    watch: {
        isAuthenticated() {
            this.pageData = null;
            setTimeout(() => {
                this.fetchPage();
            }, 50);
        },
    },
    created() {
        if (this.isAuthenticated) {
            this.handleLogout();
        } else {
            this.fetchPage();
        }
    },
    methods: {
        fetchPage() {
            fetchPageBySlug(this.isAuthenticated, "activate-account").then(
                (response) => {
                    this.pageData = response;
                }
            );
        },
        handleLogout() {
            clearData();
            this.setAuthenticated(false);
            this.$store.commit("storeRemoveAuthenticatedStatus");
            this.$store.commit("deleteUserSavedData");
            // this.$router.replace({ name: "Home" });
            window.location.reload();
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
